/* slideTransition.scss */

.pageSlider-exit > div {
  z-index: 1;
}

.pageSlider-enter > div {
  z-index: 10;
}

.pageSlider-enter .fixedButton,
.pageSlider-exit .fixedButton {
  opacity: 0 !important;
}

.pageSlider-enter.up > div {
  transform: translate3d(0, 100%, 0);
}

.pageSlider-exit.up > div {
  transform: translate3d(0, -100%, 0);
}

.pageSlider-enter.left > div {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right > div {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left > div {
  transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.right > div {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active > div {
  transform: translate3d(0, 0, 0);
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
}

.pageSlider-enter-done .fixedButton,
.pageSlider-exit-done .fixedButton {
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
